export const getProp = (
  object: object,
  path: string[],
  defaultValue: any = undefined,
): any => {
  if (path === undefined || path === null) {
    return defaultValue;
  }
  const pathArray = path;
  const val = pathArray.reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};

const setPropInObject = (
  originObject: any,
  path: string[],
  iteration: number,
  setObject: any,
): any => {
  if (path[iteration]) {
    return {
      ...originObject,
      [path[iteration]]: path[iteration + 1]
        ? {
            ...setPropInObject(
              getProp(originObject, [path[iteration]], {}),
              path,
              iteration + 1,
              setObject,
            ),
          }
        : setObject,
    };
  }
};
export const setProp = (
  originObject: any,
  path: string[],
  setObject: any,
): any => {
  const result = setPropInObject(originObject, path, 0, setObject);
  return result;
};

const dictionary = {
  'Live stretnutie': 'Live Match',
  hráči: 'players',
  'Striedajúci hráč': 'Substitute',
  'Striedaný hráč': 'Substituted',
  'Potrestaný hráč': 'Player',
  Dôvod: 'Reason',
  Strelec: 'Player',
  Začiatok: 'Start',
  Koniec: 'End',
  Čas: 'Time',
  Družstvo: 'Team',
  Polčas: 'Period',
  'Typ gólu': 'Type of goal',
  'Strelec gólu': 'Scored by',
  Asistencia: 'Assisted by',
  Uložiť: 'Save',
  'Uložiť a uzavrieť': 'Save and close',
  'Kontumácia stretnutia': 'Forfeiture',
  Zmazať: 'Delete',
  'Športové objekty': 'Sport Grounds',
  Družstvá: 'Teams',
  Súťaže: 'Competitions',
  Stretnutia: 'Matches',
  Nastavenia: 'Settings',
  'Nastavenia domény': 'Domain',
  'Nastavenia verejnej časti': 'Public portal',
  Sezóny: 'Seasons',
  'Vekové kategórie': 'Age categories',
  'Kniha rekordov': 'Records',
  'Správa obsahu': 'CMS',
};

export const __ = (toTranslate: string): string => {
  if (!(window as any).appSpace || (window as any).appSpace !== 'bart.sk') {
    return toTranslate;
  }
  return dictionary[toTranslate] || toTranslate;
};

export const required = (value: string): string | null => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

export const isInteger = (value: string | number): string | null => {
  if (!value || Number.isInteger(Number(value))) {
    return null;
  }
  return __('Hodnota musí byť celé číslo');
};

export const isTime = (value: string) => {
  if (typeof value === 'number') {
    return null;
  }
  if (
    !value ||
    (value &&
      !value.match(/^[0-9][0-9]:[0-5][0-9]$/g) &&
      !value.match(/^[0-9][0-9][0-9]:[0-5][0-9]$/g))
  ) {
    return __('Zadajte čas v tvare 00:00');
  }
  return null;
};

export const isScore = (value: string) => {
  if (value && !Array.isArray(value)) {
    const split = value.split(':');
    if (split.length === 2 && split[0] !== '' && split[1] !== '') {
      return null;
    }
    return __('Zadajte skóre vo formáte X:X');
  }
  return null;
};

export const parseToTimeString = (value: number, padMinutes?: boolean) => {
  let minutes = `${Math.ceil(value / 60)}`;
  minutes = `${Math.floor(value / 60)}`;
  if (padMinutes) {
    minutes = minutes.padStart(2, '0');
  }
  const seconds = String(value % 60).padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export const transformQueryParameters = (
  queryParameters: string,
): { [key: string]: string } =>
  queryParameters
    .substr(1)
    .split('&')
    .reduce((acc, i) => {
      const split = decodeURIComponent(i).split('=');
      return { ...acc, [split[0]]: split[1] };
    }, {});

export const formatSecondsToString = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const hoursRest = seconds % 3600;
  const m = String(Math.floor(hoursRest / 60)).padStart(2, '0');
  const rest = hoursRest % 60;
  let s = String(Math.round(rest * 100) / 100).padStart(2, '0');
  const secondsSplit = s.split('.');
  if (secondsSplit.length === 2) {
    s = `${
      secondsSplit[0].length < 2 ? `0${secondsSplit[0]}` : secondsSplit[0]
    }.${secondsSplit[1].length < 2 ? `${secondsSplit[1]}0` : secondsSplit[1]}`;
  }
  return h > 0 ? `${String(h).padStart(2, '0')}:${m}:${s}` : `${m}:${s}`;
};

export const formatStringToSeconds = (value: string) => {
  const arr = value.split(':');
  const secondsSplit = arr[arr.length - 1].split('.');
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  if (arr.length === 3) {
    hours = Number(arr[0]);
    minutes = Number(arr[1]);
  } else if (arr.length === 2) {
    minutes = Number(arr[0]);
  }
  seconds =
    Number(hours) * 3600 +
    Number(minutes) * 60 +
    Number(secondsSplit[0]) +
    (secondsSplit[1]
      ? Math.round(Number(secondsSplit[1].substr(0, 2))) / 100
      : 0);
  if (Number.isNaN(seconds)) {
    seconds = 0;
  }
  if (secondsSplit[1]) {
    return Number(seconds.toFixed(2));
  }
  return seconds;
};

export const parseToFloat = (i: string) => {
  if (!i) {
    return '';
  }
  if (i.endsWith(',')) {
    return i.replace(',', '.');
  }
  return i;
};

export const isFloat = (value: string) => {
  const parsed = Number(value);
  if (isNaN(parsed)) {
    return __('Zadajte prosím desatinné alebo celé číslo.');
  }
  return null;
};

export const isFloatFromObject = (i: { value: number }) => {
  return isFloat(String(i && i.value));
};

export const isNumber = (value: number) => {
  if (value && typeof value !== 'number') {
    return __('Zadajte prosím platný čas vo formáte 00:00, prípadne 00:00.00.');
  }
  return null;
};

export const isNumberFromObject = (i: any) => {
  return isNumber(i && i.value);
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>(\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    return null;
  }
  return __('Zadajte prosím platnú emailovú adresu');
};
