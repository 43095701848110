import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../rootReducer';
import sportnetApi from '../../sportnetApi';
import { isFloat, parseToFloat, required, __ } from '../../utilities';
import { AnyAction } from 'typescript-fsa';
import { RECORD_FORM_NAME } from '.';
import { IRecord, IRecordItem } from '../Records/definitions';

type OwnProps = {
  disciplines: Array<{
    _id: string;
    name: string;
    parameters: Array<{
      _id: string;
      name: string;
      values: string[];
    }>;
  }>;
  ageCategories: Array<{
    _id: string;
    name: string;
  }>;
  relatedProfiles: Array<{
    _id: string;
    name: string;
  }>;
  edit: boolean;
  initialValues: IRecord;
};

const mapStateToProps = (state: State, props: OwnProps) => {
  const selector = formValueSelector(RECORD_FORM_NAME);
  return {
    discipline: selector(state, 'discipline'),
    records: selector(state, 'records') || [],
    newRecord: selector(state, 'newRecord'),
  };
};

type Props = ReturnType<typeof mapStateToProps> &
  OwnProps & { dispatch: ThunkDispatch<any, {}, AnyAction> };

class RecordForm extends React.PureComponent<Props> {
  state: {
    tempAgeCategory: null | { _id: null; name: string };
    tempRelatedProfiles: Array<{ _id: null; name: string }>;
    users: {
      [key: string]: Array<{
        _id: string;
        name: string;
      }>;
    };
    errors: string[];
  } = {
    tempAgeCategory: null,
    tempRelatedProfiles: [],
    users: {},
    errors: [],
  };

  componentDidMount() {
    if (Object.keys(this.props.initialValues).length > 0) {
      this.getTemporaryData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      Object.keys(prevProps.initialValues).length === 0 &&
      Object.keys(this.props.initialValues).length > 0
    ) {
      this.getTemporaryData();
    }
  }

  getTemporaryData = () => {
    const clubs: Array<{ _id: string; name: string }> = [];
    let users = {};
    this.props.initialValues.records.forEach((record) => {
      if (!record.club._id) {
        this.setState({
          tempRelatedProfiles: [...this.state.tempRelatedProfiles, record.club],
        });
      } else {
        clubs.push(record.club);
      }
      users = {
        ...users,
        [record.club.name]: [
          ...(users[record.club.name] || []),
          ...record.holders,
        ],
      };
    });
    this.setState(
      {
        users,
      },
      () => {
        clubs.forEach((club) => {
          this.fetchClubPlayers(club._id, club.name);
        });
      },
    );
  };

  fetchClubPlayers = async (appSpace: string, clubName: string) => {
    try {
      const res = await sportnetApi.organizationPPOUsers(appSpace, {
        limit: 10000,
      });
      this.setState({
        users: {
          ...this.state.users,
          [clubName]: [
            ...(this.state.users[clubName] || []),
            ...(res.users || []).map((user) => ({
              _id: user._id || '',
              name: `${user.surname || ''} ${user.name || ''}`,
            })),
          ],
        },
      });
    } catch (e: any) {
      //
    }
  };

  render() {
    const {
      discipline,
      disciplines,
      records,
      ageCategories: permAgeCategories,
      relatedProfiles: permRelatedProfiles,
      edit,
      newRecord,
    } = this.props;
    const { tempAgeCategory, tempRelatedProfiles, users } = this.state;

    const ageCategories = [
      ...permAgeCategories,
      ...(tempAgeCategory ? [tempAgeCategory] : []),
    ];

    const relatedProfiles = [...permRelatedProfiles, ...tempRelatedProfiles];

    return (
      <>
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Disciplína')}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12} s={4}>
              <Field
                label={__('Pohlavie')}
                component={FormField}
                name="gender"
                type="select"
                required
                validate={[required]}
                disabled={edit}
              >
                <option />
                <option value="M">{__('Muži')}</option>
                <option value="F">{__('Ženy')}</option>
                <option value="X">{__('Mix')}</option>
              </Field>
            </Col>
            <Col xs={12} s={4}>
              <Field
                label={__('Veková kategória')}
                component={FormField}
                name="ageCategory"
                type="theselect"
                required
                validate={[required]}
                options={ageCategories.map((o) => ({
                  label: o && o.name,
                  value: o && o.name,
                }))}
                disabled={edit}
                parse={(item: any) => {
                  if (!item) {
                    return '';
                  }
                  const option = ageCategories.find(
                    (o) => o && o.name === item.value,
                  );

                  if (!option) {
                    return '';
                  }
                  return option;
                }}
                format={(i: { _id: string; name: string }) => {
                  if (i) {
                    const option = ageCategories.find(
                      (o) => o && o.name === (i.name || ''),
                    );
                    if (option) {
                      return {
                        value: i.name || '',
                        label: option.name,
                      };
                    }
                  }
                  return '';
                }}
                createNewOption={(i: string) => {
                  const obj = {
                    _id: i,
                    name: i,
                  };
                  this.setState(
                    {
                      tempAgeCategory: obj,
                    },
                    () => {
                      this.props.dispatch(
                        change(RECORD_FORM_NAME, 'ageCategory', obj),
                      );
                    },
                  );
                }}
              />
            </Col>
            <Col xs={12} s={4}>
              <Field
                label={__('Disciplína')}
                component={FormField}
                name="discipline"
                type="theselect"
                required
                validate={[required]}
                options={disciplines.map((o) => ({
                  label: o.name,
                  value: o._id,
                }))}
                parse={(item: any) => {
                  if (!item) {
                    return '';
                  }
                  const option = disciplines.find((o) => o._id === item.value);

                  if (!option) {
                    return '';
                  }
                  return option;
                }}
                format={(i: { _id: string; name: string }) => {
                  if (i) {
                    const option = disciplines.find(
                      (o) => o._id === (i._id || ''),
                    );
                    if (option) {
                      return {
                        value: i._id || '',
                        label: option.name,
                      };
                    }
                  }
                  return '';
                }}
                disabled={edit}
              />
            </Col>
          </Row>
        </Segment>
        {disciplines.length > 0 && !!discipline && (
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Parametre disciplíny')}
              </SegmentHeader>
            }
          >
            <Row>
              {disciplines
                .find((i) => i._id === discipline._id)!
                .parameters.map((i, idx) => (
                  <Col key={i._id} xs={12} s={4}>
                    <Field
                      component={FormField}
                      label={i.name}
                      name={`discipline.parameters.${idx}`}
                      type="theselect"
                      disabled={edit}
                      options={i.values.map((v) => ({
                        value: v,
                        label: v,
                      }))}
                      parse={(item: any) => {
                        if (!item) {
                          return '';
                        }
                        return {
                          _id: i._id,
                          name: i.name,
                          value: item.value,
                        };
                      }}
                      format={(item: {
                        _id: string;
                        name: string;
                        value: string;
                      }) => {
                        if (item && item.value) {
                          return {
                            value: item.value,
                            label: item.value,
                          };
                        }
                        return '';
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </Segment>
        )}
        <>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator collapsible>
                {__('Nový rekord')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} s={6}>
                <Field
                  label={__('Dátum dosiahnutia rekordu')}
                  component={FormField}
                  name={`newRecord.date`}
                  type="date"
                  required
                  validate={[required]}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  label={__('Miesto dosiahnutia rekordu')}
                  component={FormField}
                  name={`newRecord.place`}
                  required
                  validate={[required]}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  label={__('Typ rekordu')}
                  component={FormField}
                  name={`newRecord.record.type`}
                  required
                  type="select"
                  validate={[required]}
                >
                  <option />
                  <option value="time">{__('Čas')}</option>
                  <option value="points">{__('Body')}</option>
                </Field>
              </Col>
              <Col xs={12} s={6}>
                <Field
                  label={__('Bodové alebo sekundové vyjadrenie rekordu')}
                  component={FormField}
                  name={`newRecord.record.value`}
                  parse={parseToFloat}
                  required
                  validate={[required, isFloat]}
                />
              </Col>
              <Col xs={12} s={12}>
                <Field
                  label={__('Klub')}
                  component={FormField}
                  placeholder={__(
                    'Vyberte zo zoznamu alebo zadajte názov klubu  ',
                  )}
                  name={`newRecord.club`}
                  type="theselect"
                  required
                  validate={[required]}
                  options={relatedProfiles.map((o) => ({
                    label: o && o.name,
                    value: o && (o._id || o.name),
                  }))}
                  parse={(item: any) => {
                    if (!item) {
                      return '';
                    }
                    const option = relatedProfiles.find(
                      (o) =>
                        o && (o._id === item.value || o.name === item.value),
                    );

                    if (!option) {
                      return '';
                    }
                    return option;
                  }}
                  format={(i: { _id: string; name: string }) => {
                    if (i) {
                      const option = relatedProfiles.find(
                        (o) => o && o.name === (i.name || ''),
                      );
                      if (option) {
                        return {
                          value: i.name || '',
                          label: option.name,
                        };
                      }
                    }
                    return '';
                  }}
                  onChange={(item: any) => {
                    this.fetchClubPlayers(item.value, item.label);
                    return item;
                  }}
                  createNewOption={(i: string) => {
                    const obj = {
                      _id: i,
                      name: i,
                    };
                    this.setState(
                      {
                        tempRelatedProfiles: [
                          ...this.state.tempRelatedProfiles,
                          obj,
                        ],
                      },
                      () => {
                        this.props.dispatch(
                          change(RECORD_FORM_NAME, 'newRecord.club', obj),
                        );
                      },
                    );
                  }}
                />
              </Col>
              {!!newRecord && !!newRecord.club && (
                <>
                  {[...(newRecord.holders || []), ''].map(
                    (holder: any, idx: number) => (
                      <Col xs={12} key={idx}>
                        <Field
                          component={FormField as any}
                          name={`newRecord.holders.${idx}`}
                          type="theselect"
                          label={__('Držiteľ rekordu')}
                          placeholder={__(
                            'Vyberte zo zoznamu alebo napíšte priezvisko a meno držiteľa rekordu    ',
                          )}
                          {...(idx === 0 ? { required } : {})}
                          validate={idx === 0 ? [required] : []}
                          options={(users[newRecord.club.name] || []).map(
                            (o: { _id: string | null; name: string }) => ({
                              label: o && o.name,
                              value: o && (o._id || o.name),
                            }),
                          )}
                          parse={(item: { label: string; value: string }) => {
                            if (!item) {
                              return '';
                            }
                            const option = (
                              users[newRecord.club.name] || []
                            ).find(
                              (o: { _id: string | null; name: string }) =>
                                (item.value && item.value === o._id) ||
                                item.label === o.name,
                            );

                            if (!option) {
                              return '';
                            }
                            return option;
                          }}
                          format={(item: { _id: string; name: string }) => {
                            if (item) {
                              const option = (
                                users[newRecord.club.name] || []
                              ).find(
                                (o: { _id: string | null; name: string }) =>
                                  (item._id && item._id === o._id) ||
                                  item.name === o.name,
                              );
                              if (option) {
                                return {
                                  value: item.name || '',
                                  label: option.name,
                                };
                              }
                            }
                            return '';
                          }}
                          createNewOption={(i: string) => {
                            const obj = {
                              _id: i,
                              name: i,
                            };
                            this.setState(
                              {
                                users: {
                                  ...this.state.users,
                                  [newRecord.club.name]: [
                                    ...(this.state.users[newRecord.club.name] ||
                                      []),
                                    obj,
                                  ],
                                },
                              },
                              () => {
                                this.props.dispatch(
                                  change(
                                    RECORD_FORM_NAME,
                                    `newRecord.holders.${idx}`,
                                    obj,
                                  ),
                                );
                              },
                            );
                          }}
                        />
                      </Col>
                    ),
                  )}
                </>
              )}
            </Row>
          </Segment>
        </>
        {(records || []).map((record: IRecordItem, ridx: number) => {
          return (
            <>
              <Segment
                raised
                header={
                  <SegmentHeader
                    size="s"
                    withSeparator
                    collapsible
                    isCollapsed
                    onDispose={() => {
                      const modifiedRecords = [...records];
                      delete modifiedRecords[ridx];
                      this.props.dispatch(
                        change(
                          RECORD_FORM_NAME,
                          'records',
                          modifiedRecords.filter((i) => i),
                        ),
                      );
                    }}
                  >
                    {record
                      ? `${__('Rekord')} - ${format(
                          new Date(record.date),
                          'dd.MM.yyyy',
                        )}`
                      : __('Nový rekord')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col xs={12} s={6}>
                    <Field
                      label={__('Dátum dosiahnutia rekordu')}
                      component={FormField}
                      name={`records.${ridx}.date`}
                      type="date"
                      disabled
                    />
                  </Col>
                  <Col xs={12} s={6}>
                    <Field
                      label={__('Miesto dosiahnutia rekordu')}
                      component={FormField}
                      name={`records.${ridx}.place`}
                      disabled
                    />
                  </Col>
                  <Col xs={12} s={6}>
                    <Field
                      label={__('Typ rekordu')}
                      component={FormField}
                      name={`records.${ridx}.record.type`}
                      type="select"
                      disabled
                    >
                      <option />
                      <option value="time">{__('Čas')}</option>
                      <option value="points">{__('Body')}</option>
                    </Field>
                  </Col>
                  <Col xs={12} s={6}>
                    <Field
                      label={__('Bodové alebo sekundové vyjadrenie rekordu')}
                      component={FormField}
                      name={`records.${ridx}.record.value`}
                      parse={parseToFloat}
                      disabled
                    />
                  </Col>
                  <Col xs={12} s={12}>
                    <Field
                      label={__('Klub')}
                      component={FormField}
                      placeholder={__(
                        'Vyberte zo zoznamu alebo zadajte názov klubu  ',
                      )}
                      name={`records.${ridx}.club`}
                      type="theselect"
                      disabled
                      options={relatedProfiles.map((o) => ({
                        label: o && o.name,
                        value: o && (o._id || o.name),
                      }))}
                      parse={(item: any) => {
                        if (!item) {
                          return '';
                        }
                        const option = relatedProfiles.find(
                          (o) =>
                            o &&
                            (o._id === item.value || o.name === item.value),
                        );

                        if (!option) {
                          return '';
                        }
                        return option;
                      }}
                      format={(i: { _id: string; name: string }) => {
                        if (i) {
                          const option = relatedProfiles.find(
                            (o) => o && o.name === (i.name || ''),
                          );
                          if (option) {
                            return {
                              value: i.name || '',
                              label: option.name,
                            };
                          }
                          return { value: i.name, label: i.name };
                        }
                        return '';
                      }}
                      createNewOption={(i: string) => {
                        const obj = {
                          _id: i,
                          name: i,
                        };
                        this.setState(
                          {
                            tempRelatedProfile: obj,
                          },
                          () => {
                            this.props.dispatch(
                              change(RECORD_FORM_NAME, 'club', obj),
                            );
                          },
                        );
                      }}
                    />
                  </Col>
                  {(record.holders || []).map((holder, idx) => (
                    <Col xs={12} key={idx}>
                      <Field
                        component={FormField as any}
                        name={`records.${ridx}.holders.${idx}`}
                        type="theselect"
                        label={__('Držiteľ rekordu')}
                        disabled
                        placeholder={__(
                          'Vyberte zo zoznamu alebo napíšte priezvisko a meno držiteľa rekordu    ',
                        )}
                        {...(idx === 0 ? { required } : {})}
                        validate={idx === 0 ? [required] : []}
                        options={(users[record.club.name] || []).map(
                          (o: { _id: string | null; name: string }) => ({
                            label: o && o.name,
                            value: o && (o._id || o.name),
                          }),
                        )}
                        parse={(item: { label: string; value: string }) => {
                          if (!item) {
                            return '';
                          }
                          const option = (users[record.club.name] || []).find(
                            (o: { _id: string | null; name: string }) =>
                              (item.value && item.value === o._id) ||
                              item.label === o.name,
                          );

                          if (!option) {
                            return '';
                          }
                          return option;
                        }}
                        format={(item: { _id: string; name: string }) => {
                          if (item) {
                            const option = (users[record.club.name] || []).find(
                              (o: { _id: string | null; name: string }) =>
                                (item._id && item._id === o._id) ||
                                item.name === o.name,
                            );
                            if (option) {
                              return {
                                value: item.name || '',
                                label: option.name,
                              };
                            }
                          }
                          return '';
                        }}
                        createNewOption={(i: string) => {
                          const obj = {
                            _id: i,
                            name: i,
                          };
                          this.setState(
                            {
                              users: {
                                ...users,
                                [record.club.name]: [
                                  ...(users[record.club.name] || []),
                                  obj,
                                ],
                              },
                            },
                            () => {
                              this.props.dispatch(
                                change(RECORD_FORM_NAME, `holders.${idx}`, obj),
                              );
                            },
                          );
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Segment>
            </>
          );
        })}
      </>
    );
  }
}

const connected = reduxForm<any, any>({
  enableReinitialize: true,
})(RecordForm);

export default connect(mapStateToProps)(connected);
