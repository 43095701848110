import SubmitButton from '@sportnet/ui/Button/Submit';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { formValueSelector, submit } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import api from '../../api';
import { State } from '../../rootReducer';
import sportnetApi from '../../sportnetApi';
import { __ } from '../../utilities';
import { AnyAction } from 'typescript-fsa';
import { activeAppspaceProfileSelector } from '../App/selectors';
import { getRecordById } from '../Records/actions';
import { recordByIdSelector } from '../Records/selectors';
import RecordForm from './form';

export const RECORD_FORM_NAME = 'RECORD_FORM';

type RouteProps = RouteComponentProps<{ appspace: string; recordId: string }>;

const mapStateToProps = (state: State, props: RouteProps) => {
  const selector = formValueSelector(RECORD_FORM_NAME);
  return {
    record: recordByIdSelector(props.match.params.recordId)(state),
    ppo: activeAppspaceProfileSelector(state),
    club: selector(state, 'club'),
  };
};

type Props = ReturnType<typeof mapStateToProps> &
  RouteProps & {
    dispatch: ThunkDispatch<any, {}, AnyAction>;
  };

class RecordDetail extends React.PureComponent<
  Props,
  {
    isSubmitting: boolean;
    hasSubmitFailed: boolean;
    disciplines: any[];
    ageCategories: any[];
    relatedProfiles: any[];
  }
> {
  state = {
    isSubmitting: false,
    hasSubmitFailed: false,
    disciplines: [],
    ageCategories: [],
    relatedProfiles: [],
  };

  componentDidMount() {
    this.getDisciplines();
    this.getRelatedProfiles();
    this.getAgeCategories();
    if (this.props.match.params.recordId !== 'new') {
      this.getRecordData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.recordId !== prevProps.match.params.recordId) {
      this.getDisciplines();
      this.getRelatedProfiles();
      this.getAgeCategories();
      if (this.props.match.params.recordId !== 'new') {
        this.getRecordData();
      }
    }
  }

  getRecordData = async () => {
    const {
      match: {
        params: { appspace, recordId },
      },
    } = this.props;
    this.props.dispatch(getRecordById.action({ appspace, id: recordId }));
  };

  getAgeCategories = async () => {
    const { ppo } = this.props;
    if (ppo) {
      try {
        const res = await api.adminGetRecordsAgeCategories(ppo._id);
        this.setState({
          ageCategories: res.items?.map((item: any) => {
            return {
              _id: item._id || item.name,
              name: item.name,
            };
          }),
        });
      } catch (e: any) {
        //
      }
    }
  };

  getDisciplines = async () => {
    const { ppo } = this.props;
    if (ppo) {
      try {
        const res = await api.getSettingBySportSector(
          'sport_sector_disciplines',
          ppo.sport,
        );
        this.setState({
          disciplines: res.items,
        });
      } catch (e: any) {
        //
      }
    }
  };

  getRelatedProfiles = async () => {
    const { ppo } = this.props;
    if (ppo) {
      try {
        const res = await sportnetApi.organizationPPOListRelatedPPOs(ppo._id);
        this.setState({
          relatedProfiles: res.items || [],
        });
      } catch (e: any) {
        //
      }
    }
  };

  submit = async (values: any) => {
    const {
      match: {
        params: { appspace, recordId },
      },
      ppo,
    } = this.props;
    this.setState({
      isSubmitting: true,
      hasSubmitFailed: false,
    });
    try {
      if (recordId === 'new' && ppo) {
        const { newRecord, ...data } = values;
        const res = await api.adminCreateRecord(
          appspace,
          {},
          { ...data, records: [newRecord], sport: ppo.sport },
        );
        this.props.history.push(`/admin/${appspace}/records/${res._id}`);
      } else if (ppo) {
        const {
          ageCategory,
          appSpace,
          discipline,
          gender,
          sport,
          _id,
          newRecord,
          ...data
        } = values;

        const records = [...data.records];
        if (newRecord) {
          records.unshift(newRecord);
        }

        await api.adminUpdateRecord(
          appspace,
          recordId,
          {},
          { ...data, records },
        );
        this.getRecordData();
      }
    } catch (e: any) {
      if (e.details.name === 'RECORD_ALREADY_EXISTS') {
        alert(
          __(
            'Rekord pre dané pohlavie, vekovú kategóriu, disciplínu a parametre už existuje.',
          ),
        );
      }
      this.setState({
        hasSubmitFailed: true,
      });
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  render() {
    const {
      record,
      match: {
        params: { appspace, recordId },
      },
    } = this.props;

    return (
      <ScrollLayout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/records`}
            />
            <HeaderBar.Header>{__('Detail rekordu')}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <SubmitButton
                onClick={() => {
                  this.props.dispatch(submit(RECORD_FORM_NAME));
                }}
                isSubmitting={this.state.isSubmitting}
                isError={this.state.hasSubmitFailed}
                successText={__('Uložené')}
              >
                {__('Uložiť')}
              </SubmitButton>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {/* <AppContext
          title={__('Detail rekordu')}
          breadcrumbs={[
            {
              name: __('Zoznam rekordov'),
              url: `/admin/${appspace}/records`,
            },
          ]}
        /> */}
        <Segment>
          {(recordId === 'new' || !!Object.keys(record).length) && (
            <RecordForm
              disciplines={this.state.disciplines}
              ageCategories={this.state.ageCategories}
              relatedProfiles={this.state.relatedProfiles}
              form={RECORD_FORM_NAME}
              onSubmit={this.submit}
              initialValues={record}
              edit={record._id}
            />
          )}
        </Segment>
      </ScrollLayout>
    );
  }
}

export default compose<any>(withRouter, connect(mapStateToProps))(RecordDetail);
