import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import api from '../../../../api';
import LoaderWrapper from '../../../../components/LoaderWrapper';
import { __ } from '../../../../utilities';

const STATUS = {
  CHECK: __('Kontrola'),
  DETAILED_CHECK: __('Detailná Kontrola'),
  REVIEW: __('OFR'),
};

const SUBJECT = {
  GOAL: __('Gól'),
  PENALTY: __('Pokutový kop'),
  RED_CARD: __('Červená karta'),
  MISTAKEN_IDENTITY: __('Zámena identity'),
} as const;

const DETAILED_SUBJECTS_BY_SUBJECT: {
  [key in keyof typeof SUBJECT]: { [key: string]: string };
} = {
  GOAL: {
    ATTACKING_FOUL: __('Útočný faul'),
    OFFSIDE: __('Offside'),
    BALL_OUT: __('Lopta mimo HP'),
  },
  PENALTY: {
    ATTACKING_FOUL: __('Útočný faul'),
    FOUL: __('Faul'),
    OFFSIDE: __('Offside'),
    PENALTY_OUTSIDE: __('Mimo PU'),
    BALL_OUT: __('Lopta mimo HP'),
  },
  RED_CARD: {
    SERIOUS_FOUL_PLAY: __('Surová hra'),
    VIOLENT_CONDUCT: __('HNS'),
    DOGSO: __('Zmarenie gólovej akcie'),
  },
  MISTAKEN_IDENTITY: {
    IDENTITY_CHECK: __('Kontrola identity'),
  },
};

const VERDICT = {
  CHECK_OVER: __('Kontrola ukončená'),
  YES: __('Rozhodnutie - ÁNO'),
  NO: __('Rozhodnutie - NIE'),
} as const;

type OwnProps = {
  competitionId: string;
  partId: string;
  matchId: string;
};

type Props = OwnProps;

const VARBoard: React.FC<Props> = ({ competitionId, partId, matchId }) => {
  const initialValues: {
    status: string;
    subject?: string;
    detailedSubject?: string;
    verdict?: string;
  } = {
    status: '',
    subject: undefined,
    detailedSubject: undefined,
    verdict: undefined,
  };
  const [selectedValues, setSelectedValues] = React.useState(initialValues);

  const [isFetching, setIsFetching] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  let isSubmitting = React.useRef(false);
  let successTimeout = React.useRef<NodeJS.Timeout | null>(null);
  let submitInterval = React.useRef<Array<NodeJS.Timeout | null>>([]);

  React.useEffect(() => {
    setIsFetching(true);
    api
      .matchManagerGetVarEvents(competitionId, partId, matchId, {})
      .then((res) => {
        if (res.varEvents && (res.varEvents || []).length) {
          const lastEvent = res.varEvents[res.varEvents.length - 1];
          if (!lastEvent.verdict) {
            setSelectedValues({
              status: lastEvent.status,
              subject: lastEvent.subject,
              detailedSubject: lastEvent.detailedSubject,
            });
          }
        }
      })
      .catch((e) => {
        console.error(e);
        alert(__('Nepodarilo sa získať aktuálny stav VAR'));
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [competitionId, partId, matchId]);

  const submitCurrentValue = async (currentValue: typeof initialValues) => {
    try {
      isSubmitting.current = true;
      await api.matchManagerAddVarEvent(
        competitionId,
        partId,
        matchId,
        {},
        {
          ...currentValue,
          description: [
            STATUS[currentValue.status] || currentValue.status,
            currentValue.subject &&
              (SUBJECT[currentValue.subject] || currentValue.subject),
            currentValue.subject &&
              currentValue.detailedSubject &&
              ((DETAILED_SUBJECTS_BY_SUBJECT[currentValue.subject] || {})[
                currentValue.detailedSubject
              ] ||
                currentValue.detailedSubject),
            currentValue.verdict && VERDICT[currentValue.verdict],
          ]
            .filter(Boolean)
            .join(' / '),
        },
      );
    } catch (e) {
      console.error(e);
    } finally {
      isSubmitting.current = false;
    }
  };

  const setAndSubmitValues = (modifiedValues: {
    [key: string]: string | undefined;
  }) => {
    const constructedValues = { ...selectedValues, ...modifiedValues };
    setSelectedValues(constructedValues);
    if (!isSubmitting.current) {
      submitCurrentValue(constructedValues);
    } else {
      const index = submitInterval.current.length;
      submitInterval.current[index] = setInterval(() => {
        if (!isSubmitting.current && !submitInterval.current[index - 1]) {
          clearInterval(submitInterval.current![index]!);
          submitCurrentValue(constructedValues);
          submitInterval.current[index] = null;
        }
      }, 100);
    }
  };

  const submit = async () => {
    setIsSubmitted(true);
    successTimeout.current = setTimeout(() => {
      setIsSubmitted(false);
      setSelectedValues(initialValues);
    }, 3000);
  };

  const renderStatus = (
    status: string,
    statusIdx: number,
    statuses: string[],
  ) => {
    const selectedStatusIdx = statuses.findIndex(
      (i) => i === selectedValues.status,
    );
    return (
      <Col key={status} xs={(12 / Object.keys(STATUS).length) as any}>
        <Button
          onClick={() => {
            if (isSubmitted && successTimeout.current) {
              clearTimeout(successTimeout.current);
              setAndSubmitValues({
                ...initialValues,
                status,
              });
              setIsSubmitted(false);
            } else {
              setAndSubmitValues({
                status,
              });
            }
          }}
          block
          disabled={statusIdx > selectedStatusIdx + 1}
          primary={statusIdx <= selectedStatusIdx && !isSubmitted}
          success={statusIdx <= selectedStatusIdx && isSubmitted}
        >
          {STATUS[status]}
        </Button>
      </Col>
    );
  };

  const renderSubject = (subject: string) => (
    <Col key={subject} xs={(12 / Object.keys(SUBJECT).length) as any}>
      <Button
        onClick={() => {
          setAndSubmitValues({
            subject,
            detailedSubject: undefined,
          });
        }}
        disabled={!selectedValues.status}
        block
        primary={selectedValues.subject === subject && !isSubmitted}
        success={selectedValues.subject === subject && isSubmitted}
      >
        {SUBJECT[subject]}
      </Button>
    </Col>
  );

  const renderDetailedSubject = (subject: string) => (
    <Col
      key={subject}
      xs={
        (12 /
          Object.keys(
            DETAILED_SUBJECTS_BY_SUBJECT[selectedValues.subject || ''],
          ).length) as any
      }
    >
      <Button
        onClick={() => {
          setAndSubmitValues({
            detailedSubject: subject,
          });
        }}
        block
        primary={selectedValues.detailedSubject === subject && !isSubmitted}
        success={selectedValues.detailedSubject === subject && isSubmitted}
      >
        {DETAILED_SUBJECTS_BY_SUBJECT[selectedValues.subject || ''][subject]}
      </Button>
    </Col>
  );

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Segment raised>
        <Row>{Object.keys(STATUS).map(renderStatus)}</Row>
      </Segment>
      <Segment raised>
        <Row>{Object.keys(SUBJECT).map(renderSubject)}</Row>
      </Segment>
      {selectedValues.subject && (
        <Segment raised>
          <Row>
            {Object.keys(
              DETAILED_SUBJECTS_BY_SUBJECT[selectedValues.subject || ''],
            ).map(renderDetailedSubject)}
          </Row>
        </Segment>
      )}
      <Segment raised>
        <Row>
          <Col xs={4}>
            <Button
              success={isSubmitted && selectedValues.verdict === 'CHECK_OVER'}
              block
              disabled={!selectedValues.status}
              onClick={() => {
                setAndSubmitValues({ verdict: 'CHECK_OVER' });
                submit();
              }}
            >
              {VERDICT['CHECK_OVER']}
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              success={isSubmitted && selectedValues.verdict === 'YES'}
              block
              disabled={!selectedValues.detailedSubject}
              onClick={() => {
                setAndSubmitValues({
                  verdict: 'YES',
                });
                submit();
              }}
            >
              {VERDICT['YES']}
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              success={isSubmitted && selectedValues.verdict === 'NO'}
              block
              disabled={!selectedValues.detailedSubject}
              onClick={() => {
                setAndSubmitValues({
                  verdict: 'NO',
                });
                submit();
              }}
            >
              {VERDICT['NO']}
            </Button>
          </Col>
        </Row>
      </Segment>
    </>
  );
};

export default VARBoard;
